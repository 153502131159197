<template>
  <div id="app">
    <component :is="layout">
      <router-view></router-view>
    </component>
  </div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "./store/utils";
import { NS_USER } from "./store/namespace.names";
import { GET_TOKEN } from "./store/getter.names";
import { FETCH_PACKAGES } from "./store/action.names";
import { SET_TOKEN_ERROR } from "./store/mutation.names";

@Component({
  name: "App",
  components: {
  }
})
export default class App extends Vue {
  @Action(namespaced(NS_USER, FETCH_PACKAGES)) fetchPackages;

  @Getter(namespaced(NS_USER, GET_TOKEN)) token;
  @Mutation(namespaced(NS_USER, SET_TOKEN_ERROR)) setTokenError;

  get layout(){
    return (this.$route.meta.layout);
  }

  @Watch("token")
  hadleTokenChange(val, oldVal) {
    if (val != oldVal) {
      if (val == null) {
        this.setTokenError();
        if(this.$route.meta.loginRequired === true){
          this.$router.push("/");
        }
      }
    }
  }

  mounted(){
    this.fetchPackages();
  }
}
</script>


<style lang="scss">
  @import "./assets/css/style.scss";
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sui-modal',{attrs:{"closable":false,"closeIcon":true,"size":"small"},on:{"displayChanged":_vm.displayChanged},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"left-side"},[_c('div',{staticClass:"top-text"},[_c('h1',[_vm._v("Forget Password")]),_c('p',[_vm._v("Get access to your Profile & extract business data")])]),_c('div',{staticClass:"bottom-img"})]),_c('div',{staticClass:"right-side"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.step == 1)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleSendCode.apply(null, arguments)}}},[_c('div',{staticClass:"input-wrapper"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.email),expression:"formData.email"}],attrs:{"type":"email","name":"email","placeholder":"Email"},domProps:{"value":(_vm.formData.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "email", $event.target.value)}}}),(errors[0])?_c('span',[_vm._v("* "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),(_vm.sendCode)?_c('div',{staticClass:"input-wrapper"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.otp_code),expression:"formData.otp_code"}],attrs:{"type":"text","name":"otp code","placeholder":"Otp code"},domProps:{"value":(_vm.formData.otp_code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "otp_code", $event.target.value)}}}),(errors[0])?_c('span',[_vm._v("* "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1):_vm._e(),(_vm.sendCode)?_c('div',{staticClass:"input-wrapper"},[_c('p',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleSendCode.apply(null, arguments)}}},[_vm._v(" Resend email? Click here ")])])]):_vm._e(),_c('div',{staticClass:"input-wrapper"},[_c('p',{staticClass:"error",domProps:{"innerHTML":_vm._s(_vm.error_msg)}})]),(!_vm.sendCode)?_c('div',{staticClass:"input-wrapper"},[_c('button',{staticClass:"sign-up-btn",attrs:{"disabled":invalid,"type":"submit"}},[_vm._v(" Send code ")])]):_c('div',{staticClass:"input-wrapper"},[_c('button',{staticClass:"sign-up-btn",attrs:{"disabled":invalid,"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.handleVerification.apply(null, arguments)}}},[_vm._v(" Continue ")])])]):_vm._e(),(_vm.step == 2)?_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleForgetPassword.apply(null, arguments)}}},[_c('div',{staticClass:"input-wrapper"},[_c('ValidationProvider',{attrs:{"vid":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordForm.new_password),expression:"passwordForm.new_password"}],attrs:{"type":"password","name":"new password","placeholder":"New Password"},domProps:{"value":(_vm.passwordForm.new_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.passwordForm, "new_password", $event.target.value)}}}),(errors[0])?_c('span',[_vm._v("* "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"input-wrapper"},[_c('ValidationProvider',{attrs:{"vid":"password2","rules":"required|min:8|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordForm.new_password2),expression:"passwordForm.new_password2"}],attrs:{"type":"password","name":"retype new password","placeholder":"Retype New Password"},domProps:{"value":(_vm.passwordForm.new_password2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.passwordForm, "new_password2", $event.target.value)}}}),(errors[0])?_c('span',[_vm._v("* "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"input-wrapper"},[_c('p',{staticClass:"error",domProps:{"innerHTML":_vm._s(_vm.error_msg)}})]),_c('div',{staticClass:"input-wrapper"},[_c('button',{staticClass:"sign-up-btn",attrs:{"disabled":invalid,"type":"submit"}},[_vm._v(" Reset Password ")])])]):_vm._e()]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
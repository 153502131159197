<template>
  <div class="profile-container">
    <div class="profile-nav">
      <div 
        :class="{'nav-item': true, 'active': activeMenu == menu.name}"
        v-for="(menu, idx) in menus"
        :key="'menu_' + idx"
        @click="changeMenu(menu)"
      >
        {{ menu.label }}
      </div>
    </div>
    <div class="content">
      <component :is="activeMenu">
        <router-view></router-view>
      </component>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../store/utils";
import { FETCH_GMAP } from "../store/action.names";
import { SET_OPEN_SIGN_IN } from "../store/mutation.names";
import { NS_USER } from "../store/namespace.names";
import { 
  GET_TOKEN,
  GET_PROFILE
} from "../store/getter.names";
import { API_HOST } from "../global";
import Personal from "../components/Personal";
import UserPackage from "../components/UserPackage";
import Transactions from "../components/Transactions";
import RequestData from "../components/RequestData";

@Component({
  name: "Profile",
  components: {
    "personal": Personal,
    "package": UserPackage,
    "transactions": Transactions,
    "request-data": RequestData
  }
})
export default class Profile extends Vue {
  menus = [
    {
      name: "personal",
      label: "Personal",
      link: "/profile/personal"
    },
    {
      name: "package",
      label: "Package & Limits",
      link: "/profile/package"
    },
    {
      name: "transactions",
      label: "Transactions",
      link: "/profile/transaction"
    },
    {
      name: "request-data",
      label: "Request Data",
      link: "/profile/request-data"
    }
  ];
  activeMenu = null;

  changeMenu(obj){
    this.activeMenu = obj.name;
    this.$router.push(`/profile/${obj.name}`);
  }

  mounted(){
    let slug = this.$route.params.slug;
    this.activeMenu = slug;
  }
}
</script>

<style scoped lang="scss">
  .profile-container{
    min-height: 500px;
    width: 50%;
    margin: 0 auto;
    margin-top: 100px;
    @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max) {
      width: 98%;
      margin-top: 50px;
    }
    @media (max-width: $tablet-breakpoint-max) {
      width: 80%;
      margin-top: 50px;
    }
  }
  .profile-nav{
    height: 60px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(39,33,89,0.8855917366946778) 25%, rgba(2,22,22,1) 100%, rgba(2,15,15,0.7231267507002801) 100%, rgba(15,27,27,1) 100%, rgba(52,242,242,1) 100%);
    border: 1px solid navy;
    @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max) {
      display: flex;
      flex-direction: column;
      height: auto;
    }
    .nav-item{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      font-weight: 600;
      color: white;
      cursor: pointer;
      @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max) {
        height: 60px;
      }
    }
    .active{
      background-color: white;
      color: black;
    }
  }
  .content{
    margin-top: 50px;
  }
</style>
<template>
  <div>
      <sui-modal 
        v-model="showModal" 
        :closable="false" 
        :closeIcon="true" 
        size="small"
        @displayChanged="displayChanged"
      >
        <div class="modal-body">
          <div class="left-side">
            <div class="top-text">
              <h1>Payment</h1>
              <p>Pay now</p>
            </div>
            <div class="bottom-img">
              <!-- <img src="@/assets/images/horizontal-gmap.png"> -->
            </div>
          </div>
          <div class="right-side">
            <div class="package-detail" v-if="data">
							<div class="item">
								<div class="label">
									Package: 
								</div>
								<div class="value">
									{{ data.name }}
								</div>
							</div>
							<div class="item">
								<div class="label">
									Request Limit: 
								</div>
								<div class="value">
									{{ data.request_limit }}
								</div>
							</div>
							<div class="item">
								<div class="label">
									Data/request: 
								</div>
								<div class="value">
									{{ data.data_limit_per_request }}
								</div>
							</div>
							<div class="item">
								<div class="label">
									Duration: 
								</div>
								<div class="value">
									{{ data.duration }} days
								</div>
							</div>
							<div class="item">
								<div class="label">
									Price: 
								</div>
								<div class="value">
									${{ data.price }}
								</div>
							</div>
							<div class="item">
								<div class="label">
									Vat (15%): 
								</div>
								<div class="value">
									${{ data.vat }}
								</div>
							</div>
							<sui-divider />
							<div class="item">
								<div class="label">
									Total: 
								</div>
								<div class="value">
									${{ data.total }}
								</div>
							</div>

							<div class="item paypal">
								<p>Pay with Paypal</p>
								<PayPal
									:amount="payable"
									currency="USD"
									:client="credentials"
									env="sandbox"
									@payment-authorized="paymentAuthorized"
									@payment-completed="paymentCompleted"
									@payment-cancelled="paymentCancelled"
								/>
							</div>
            </div>
          </div>
        </div>
      </sui-modal>
  </div>

</template>

<script>
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { namespaced } from "../../store/utils";
import { NS_USER } from "../../store/namespace.names";
import { GET_PROFILE } from "../../store/getter.names";
import { 
	POST_TRANSACTION
} from "../../store/action.names";
import PayPal from "vue-paypal-checkout";

@Component({
  name: "PaymentModal",
  components: {
		PayPal
  }
})
export default class PaymentModal extends Vue {
  @Prop() open;
  @Prop() data;

	@Getter(namespaced(NS_USER, GET_PROFILE)) getProfile;
	@Action(namespaced(NS_USER, POST_TRANSACTION)) postTransaction;

  showModal = false;
  error_msg = "";

	credentials = {
    sandbox: "AfWY-bnm1jS6jXcAreKWHTdBxtjUHFUlXnccOBj_ovVIrKBAtnhYFAAXqzDy5p-RDNKZS2No1ncBX8aV",
    production: ""
  };

  @Watch("open")
  changeShowModal(val, oldVal){
    this.showModal = this.open;
  }

  displayChanged(value){
    if(value === "closed"){
      this.$emit("closed");
    }
  }

  closeSigninModal(){
    this.$emit("closed");
  }

	get payable(){
		if(this.data){
			return this.data.total.toString();
		}
	}

	paymentAuthorized(evt){
		// console.log(evt);
  }

  paymentCancelled(){
  }

  paymentCompleted(data){
		const transactionId = data.id;
		const formData = {
			user: this.getProfile.id,
			provider: "paypal",
			package: this.data.id,
			transaction_id: transactionId,
			data: JSON.stringify(data)
		}
		this.postTransaction(formData).then((data) => {
			this.$emit("closed", "success");
		}).catch((e) => {
			this.error_msg = "";
			var err_msg = "";
			for (const [key, value] of Object.entries(e.response.data)) {
				const err = `<p>${value}</p>`;
				err_msg = err_msg + err;
			}
			this.error_msg = err_msg;
			Vue.$toast.open(this.error_msg);
		})
  }

  created(){
    this.showModal = this.open;
  }
}
</script>

<style scoped lang="scss">
  .modal-body{
    display: grid;
    grid-template-columns: 1.2fr 2fr;
		@media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max) {
      display: flex;
      flex-direction: column;
    }
    .left-side{
      background: rgb(2,0,36);
      background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(79,79,131,1) 0%, rgba(148,190,203,1) 39%, rgba(0,212,255,1) 100%);
      min-height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      padding-top: 60px;
			@media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max) {
        min-height: 100px;
      }
      .top-text{
        h1{
          color: black;
          font-weight: 700;
        }
        p{
          color: black;
          font-weight: 400;
          letter-spacing: 1px;
        }
      }
      .bottom-img{
        display: flex;
        justify-content: center;
        img{
          width: 300px;
        }
      }
    }
    .right-side{
      padding: 20px;
      padding-top: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      .package-detail{
				min-height: 100px;
				.item{
					display: flex;
					gap: 30px;
					margin-bottom: 15px;
					.label{
						width: 200px;
						font-weight: 600;
						font-size: 16px;
					}
					.value{
						font-weight: 400;
					}
				}
				.paypal{
					margin-top: 50px;
					display: flex;
					gap: 5px;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					p{
						font-weight: 600;
						font-size: 16px;
						text-decoration: underline;
					}
				}
      }
    }
  }
  .error{
    color: red;
  }
</style>
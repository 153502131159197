<template>
  <div>
    <Navbar />
    <div class="body-wrapper">
      <slot />
    </div>
    <Footer />
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

@Component({
  name: "DefaultLayout",
  components: {
    Navbar,
    Footer
  }
})
export default class DefaultLayout extends Vue {
  get layout(){
    return (this.$route.meta.layout);
  }  
}
</script>

<style scoped lang="scss">
  .body-wrapper{
    width: 100%;
    min-height: 100vh;
    @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max) {
      min-height: 750px;
    }
    // margin: 0 auto;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
</style>


import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../pages/Home";
import Profile from "../pages/Profile";

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
        layout: "default-layout",
        loginRequired: false,
    },
  },
  {
    path: "/profile/:slug",
    name: "profile",
    component: Profile,
    meta: {
        layout: "default-layout",
        loginRequired: true,
    },
  },
]

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView();
    }
})

export default router;

import { API_HOST } from "../global";

export const API_ROOT = `${API_HOST}/api/v1`;
export const PUBLIC_API_ROOT = `${API_HOST}/api/v1/public`;

export const FETCH_GMAP_ENDPOINT = `${PUBLIC_API_ROOT}/fetch-gmap/`
export const LOGIN_ENDPOINT = `${PUBLIC_API_ROOT}/users/sign_in/`;
export const REGISTRATION_ENDPOINT = `${PUBLIC_API_ROOT}/users/sign_up/`;
export const PROFILE_ENDPOINT = `${PUBLIC_API_ROOT}/users/profile/`;
export const FETCH_PACKAGES_ENDPOINT = `${PUBLIC_API_ROOT}/packages/`;
export const FETCH_TRANSACTIONS_ENDPOINT = `${PUBLIC_API_ROOT}/transactions/`;
export const FETCH_REQUEST_DATA_ENDPOINT = `${PUBLIC_API_ROOT}/request-data/`;
export const SEND_OTP_CODE_ENDPOINT = `${PUBLIC_API_ROOT}/users/send_verification_code/`;
export const VERIFY_CODE_ENDPOINT = `${PUBLIC_API_ROOT}/users/verification/`;
export const CHANGE_PASSWORD_ENDPOINT = `${PUBLIC_API_ROOT}/users/change_password/`;
export const FORGET_PASSWORD_ENDPOINT = `${PUBLIC_API_ROOT}/users/forget_password/`;

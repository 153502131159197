export const FETCH_GMAP = "fetchGmap";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const PROFILE = "profile";
export const REGISTRATION = "registration";
export const GET_TOKEN_FROM_LOCAL_STORE = "getTokenFromLocalStorage";
export const FETCH_PACKAGES = "fetchPackages";
export const UPDATE_PROFILE = "updateProfile";
export const FETCH_TRANSACTIONS = "fetchTransactions";
export const POST_TRANSACTION = "postTransaction";
export const FETCH_REQUST_TRACKERS = "fetchRequestTracker";
export const SEND_OTP_CODE = "sendOtpCode";
export const VERIFY_CODE = "verifyCode";
export const CHANGE_PASSWORD = "changePassword";
export const FORGET_PASSWORD = "forgetPassword";
<template>
  <div class="package">
    <div class="title">
      <span>About Us</span>
    </div>
    <div class="items">
      <div>
        Biz extractor scrape the Google My Business Data from anywhere in the world.
      </div>
      <div>
        It contains any Business Category
        Biz extractor is the BEST Lead Search tool Ever Made.
        This extractor contains <span class="yellow">Phone Number + EMAIL ID</span> (If Available) Ratings+Longitude+Latitude, Address and many more.
        In Biz extractor there is no need of any API from Google Maps.
        There is no limitation in Biz extractor and it works anywhere around the world.
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "AboutUs",
  components: {
  }
})
export default class AboutUs extends Vue {
}
</script>

<style scoped lang="scss">
  .package{
    min-height: 500px;
    width: 70%;
    margin: 0 auto;
    @media (max-width: $tablet-breakpoint-max) {
      width: 90%;
    }
    .title{
      text-align: center;
      margin-top: 50px;
      font-size: 40px;
      font-weight: 700;
      letter-spacing: 2px;
      span{
        padding-bottom: 10px;
        border-bottom: 10px solid yellow;
      }
    }
    .items{
      margin-top: 100px;
      margin-bottom: 0px;
      font-size: 25px;
      line-height: 50px;
      text-align: justify;
      background-color: #f1f1f1;
      font-weight: 300 !important;
      padding: 50px;
      margin-bottom: 100px;
      @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max), (max-width: $tablet-breakpoint-max) {
        padding: 10px;
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
  .yellow{
    background-color: yellow;
    font-weight: 600;
    padding: 10px;
  }
</style>
import {
	GET_PROFILE,
	GET_TOKEN,
	GET_AUTH_HEADER,
	GET_OPEN_SIGNIN,
	GET_PACKAGES,
} from "../getter.names"
import {
	FETCH_GMAP,
	LOGIN,
	REGISTRATION,
	PROFILE,
	GET_TOKEN_FROM_LOCAL_STORE,
	LOGOUT,
	FETCH_PACKAGES,
	UPDATE_PROFILE,
	FETCH_TRANSACTIONS,
	POST_TRANSACTION,
	FETCH_REQUST_TRACKERS,
	SEND_OTP_CODE,
	VERIFY_CODE,
	CHANGE_PASSWORD,
	FORGET_PASSWORD
} from "../action.names";
import {
  FETCH_GMAP_ENDPOINT,
	REGISTRATION_ENDPOINT,
	LOGIN_ENDPOINT,
	PROFILE_ENDPOINT,
	FETCH_PACKAGES_ENDPOINT,
	FETCH_TRANSACTIONS_ENDPOINT,
	FETCH_REQUEST_DATA_ENDPOINT,
	SEND_OTP_CODE_ENDPOINT,
	VERIFY_CODE_ENDPOINT,
	CHANGE_PASSWORD_ENDPOINT,
	FORGET_PASSWORD_ENDPOINT
} from "../endpoints";
import {
	SET_TOKEN,
	SET_PROFILE,
	SET_TOKEN_ERROR,
	SET_OPEN_SIGN_IN,
	SET_PACKAGES,
} from "../mutation.names";
import { namespaced, buildParams } from "../utils";
import axios from "axios";

export default {
	namespaced: true,
	state: {
		user: {},
		openSingin: false,
		packages: [],
		error: false
	},
	getters: {
		[GET_PROFILE](state) {
			return state.user;
		},
		[GET_TOKEN](state) {
			if(state.user){
				return state.user.token;
			}
		},
		[GET_AUTH_HEADER](state) {
			if (state.user.token == null) return null;
			return { Authorization: `Token ${state.user.token}` };
		},
		[GET_OPEN_SIGNIN](state) {
			return state.openSingin;
		},
		[GET_PACKAGES](state) {
			return state.packages;
		},
	},
	actions: {
		async [REGISTRATION]({ commit, dispatch }, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post(REGISTRATION_ENDPOINT, payload)
					.then(({ data }) => {
						commit(SET_PROFILE, data);
						commit(SET_TOKEN, data.token);
						resolve(data);
					})
					.catch((e) => {
						commit(SET_TOKEN_ERROR);
						console.log(e)
						reject(e);
					});
			})
		},
		async [LOGIN]({ commit, dispatch }, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post(LOGIN_ENDPOINT, payload)
					.then(({ data }) => {
						commit(SET_PROFILE, data);
						commit(SET_TOKEN, data.token);
						resolve(data);
					})
					.catch((e) => {
						commit(SET_TOKEN_ERROR);
						console.log(e)
						reject(e);
					});
			})
		},
		async [PROFILE]({ commit, getters }, payload) {
			return new Promise((resolve, reject) => {
				axios
					.get(PROFILE_ENDPOINT, {
						headers: {
							...getters[GET_AUTH_HEADER],
						},
					})
					.then(({ data }) => {
						commit(SET_PROFILE, data);
						resolve(data);
					})
					.catch((e) => {
						commit(SET_TOKEN_ERROR);
						console.log(e)
						reject(e);
					});
			})
		},
		async [GET_TOKEN_FROM_LOCAL_STORE]({ commit, dispatch }) {
			return new Promise((resolve, reject) => {
				const localToken = localStorage.getItem("GMAP_EXTRACTOR_TOKEN");
				if (localToken != null) {
					commit(SET_TOKEN, localToken);
					dispatch(PROFILE);
					resolve();
				} else {
					reject();
				}
			})
		},
		async [LOGOUT]({ commit }) {
			return new Promise((resolve, reject) => {
				let token = localStorage.getItem("GMAP_EXTRACTOR_TOKEN");
				if (token != null) {
					commit(SET_TOKEN_ERROR);
					resolve();
				}
			})
		},
		[UPDATE_PROFILE]({ commit, getters }, payload) {
			return new Promise((resolve, reject) => {
				axios
					.patch(`${PROFILE_ENDPOINT}`, payload, {
							headers: {
								...getters[GET_AUTH_HEADER],
							},
						})
						.then((data)=> {
							commit(SET_PROFILE, data.data);
							resolve(data);
						})
						.catch((e) => {
							// commit(SET_TOKEN_ERROR);
							console.log(e)
							reject(e);
						});
			})
		},
		[CHANGE_PASSWORD]({ commit, getters }, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post(`${CHANGE_PASSWORD_ENDPOINT}`, payload, {
							headers: {
								...getters[GET_AUTH_HEADER],
							},
						})
						.then((data)=> {
							commit(SET_PROFILE, data.data);
							resolve(data);
						})
						.catch((e) => {
							// commit(SET_TOKEN_ERROR);
							console.log(e)
							reject(e);
						});
			})
		},
		[FORGET_PASSWORD]({ commit, getters }, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post(`${FORGET_PASSWORD_ENDPOINT}`, payload)
						.then((data)=> {
							resolve(data);
						})
						.catch((e) => {
							console.log(e)
							reject(e);
						});
			})
		},
		async [FETCH_GMAP]({ commit, dispatch, getters }, payload) {
			return new Promise((resolve, reject) => {
				axios
					.post(FETCH_GMAP_ENDPOINT, payload, {
						headers: {
							...getters[GET_AUTH_HEADER],
						},
					})
					.then((data) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e)
						reject(e);
					});
			})
		},
		async [FETCH_PACKAGES]({ commit, dispatch }) {
			return new Promise((resolve, reject) => {
				axios
					.get(FETCH_PACKAGES_ENDPOINT)
					.then(({ data }) => {
						commit(SET_PACKAGES, data);
						resolve(data);
					})
					.catch((e) => {
						console.log(e)
						reject(e);
					});
			})
		},
		async [FETCH_TRANSACTIONS]({ commit, dispatch, getters }, payload) {
			return new Promise((resolve, reject) => {
				const endpoint = `${FETCH_TRANSACTIONS_ENDPOINT}${buildParams(payload)}`;
				axios
					.get(endpoint, {
						headers: {
							...getters[GET_AUTH_HEADER],
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e)
						reject(e);
					});
			})
		},
		async [POST_TRANSACTION]({ commit, dispatch, getters }, payload) {
			return new Promise((resolve, reject) => {
				const endpoint = `${FETCH_TRANSACTIONS_ENDPOINT}`;
				axios
					.post(endpoint, payload, {
						headers: {
							...getters[GET_AUTH_HEADER],
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e)
						reject(e);
					});
			})
		},
		async [FETCH_REQUST_TRACKERS]({ commit, dispatch, getters }, payload) {
			return new Promise((resolve, reject) => {
				const endpoint = `${FETCH_REQUEST_DATA_ENDPOINT}${buildParams(payload)}`;
				axios
					.get(endpoint, {
						headers: {
							...getters[GET_AUTH_HEADER],
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e)
						reject(e);
					});
			})
		},
		async [SEND_OTP_CODE]({ commit, dispatch, getters }, payload) {
			return new Promise((resolve, reject) => {
				const endpoint = `${SEND_OTP_CODE_ENDPOINT}`;
				axios
					.post(endpoint, payload, {
						headers: {
							...getters[GET_AUTH_HEADER],
						},
					})
					.then(({ data }) => {
						resolve(data);
					})
					.catch((e) => {
						console.log(e)
						reject(e);
					});
			})
		},
		async [VERIFY_CODE]({ commit, dispatch, getters }, payload) {
			return new Promise((resolve, reject) => {
				const endpoint = `${VERIFY_CODE_ENDPOINT}`;
				axios
					.post(endpoint, payload, {
						headers: {
							...getters[GET_AUTH_HEADER],
						},
					})
					.then(({ data }) => {
						commit(SET_PROFILE, data);
						resolve(data);
					})
					.catch((e) => {
						console.log(e)
						reject(e);
					});
			})
		},
	},
	mutations: {
		[SET_TOKEN](state, token) {
			state.user.token = token;
			state.error = false;
			localStorage.setItem("GMAP_EXTRACTOR_TOKEN", token);
		},
		[SET_TOKEN_ERROR](state) {
			state.user = null;
			state.error = true;
			localStorage.removeItem("GMAP_EXTRACTOR_TOKEN");
		},
		[SET_PROFILE](state, data) {
				state.user = data;
		},
		[SET_OPEN_SIGN_IN](state, data) {
			state.openSingin = data.data;
		},
		[SET_PACKAGES](state, data) {
			state.packages = data;
		},
	}
}
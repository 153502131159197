<template>
  <div class="navbar">
    <div class="nav">
      <div class="left">
        <router-link to="/">BIZ Extractor</router-link>
      </div>
      <div class="right">
        <button 
          v-if="!token"
          class="sign-in-button"
          @click.prevent="showLoginModal=true"
        >
          Sign in
        </button>
        <button 
          v-if="!token"
          class="sign-up-button"
          @click.prevent="showRegisterModal=true"
        >
          Sign up
        </button>
        <button 
          v-if="token"
          class="sign-out-button"
          @click.prevent="handleLogout"
        >
          Sign out
        </button>
        <a
          @click.prevent="handleProfileRoute"
          v-if="token && profile"
          class="profile"
        >
          <img 
            v-if="profile.image"
            :src="HOST + profile.image" 
          />
          <img 
            v-else
            src="@/assets/images/empty-user.png" 
          />
        </a>
      </div>
    </div>
    <LoginModal 
      v-if="showLoginModal"
      :open="showLoginModal" 
      @closed="loginModalClosed" 
    />
    <SignUpModal 
      v-if="showRegisterModal"
      :open="showRegisterModal" 
      @closed="registerModalClosed" 
    />
    <VerificationModal
      v-if="showVerificationModal"
      :open="showVerificationModal"
      @closed="verificationModalClosed"
    />
    <ForgetPasswordModal
      v-if="showForgetPasswordModal"
      :open="showForgetPasswordModal"
      @closed="forgetPasswordModalClosed"
    />
  </div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../store/utils";
import { LOGOUT } from "../store/action.names";
import { SET_OPEN_SIGN_IN } from "../store/mutation.names";
import { NS_USER } from "../store/namespace.names";
import { 
  GET_PROFILE,
  GET_TOKEN,
  GET_OPEN_SIGNIN
} from "../store/getter.names";
import { API_HOST } from "../global";
import {
    ValidationProvider,
    ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import LoginModal from "./modals/LoginModal";
import SignUpModal from "./modals/SignUpModal";
import VerificationModal from "./modals/VerificationModal";
import ForgetPasswordModal from "./modals/ForgetPasswordModal";

@Component({
  name: "Navbar",
  components: {
    ValidationProvider,
    ValidationObserver,
    LoginModal,
    SignUpModal,
    VerificationModal,
    ForgetPasswordModal
  }
})
export default class Navbar extends Vue {
  @Action(namespaced(NS_USER, LOGOUT)) logout;

  @Mutation(namespaced(NS_USER, SET_OPEN_SIGN_IN)) setOpenSignin;

  @Getter(namespaced(NS_USER, GET_TOKEN)) token;
  @Getter(namespaced(NS_USER, GET_PROFILE)) profile;
  @Getter(namespaced(NS_USER, GET_OPEN_SIGNIN)) getOpenSignin;

  HOST = API_HOST;
  showLoginModal = false;
  showRegisterModal = false;
  showVerificationModal = false;
  showForgetPasswordModal = false;

  @Watch("getOpenSignin")
  changeOpenSignin(val, oldVal){
    if(val == true){
      this.showLoginModal = true;
      this.setOpenSignin({data: false});
    }
  }

  loginModalClosed(value){
    this.showLoginModal = false;
    if(value == "register"){
      this.showRegisterModal = true;
    }
    if(value == "sign_in"){
      this.showVerificationModal = true;
    }
    if(value == "forget_password"){
      this.showForgetPasswordModal = true;
    }

  }

  registerModalClosed(value){
    this.showRegisterModal = false;
    if(value == "signin"){
      this.showLoginModal = true;
    }
    if(value == "register"){
      this.showVerificationModal = true;
    }
  }

  verificationModalClosed(value){
    this.showVerificationModal = false;
    if(value == "success"){
      Vue.$toast.open("Successfully verified your account!");
    }
  }

  forgetPasswordModalClosed(value){
    this.showForgetPasswordModal = false;
    if(value == "success"){
      Vue.$toast.open("Successfully reset your password!");
    }
  }

  handleLogout(){
    this.logout();
  }

  handleProfileRoute(){
    if(this.profile && !this.profile.is_verified){
      this.showVerificationModal = true;
    }else{
      this.$router.push("/profile/personal")
    }
  }
}
</script>

<style scoped lang="scss">
  .navbar{
    height: 70px;
    width: 100%;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(39,33,89,0.8855917366946778) 25%, rgba(2,22,22,1) 100%, rgba(2,15,15,0.7231267507002801) 100%, rgba(15,27,27,1) 100%, rgba(52,242,242,1) 100%);
  }
  .nav{
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max), (max-width: $tablet-breakpoint-max) {
      width: 95%;
    }
    .left{
      a{
        text-decoration: none;
        color: white;
        font-weight: 700;
        font-size: 25px;
        letter-spacing: 2px;
        @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max) {
          font-size: 18px;
        }
      }
    }
    .right{
      display: flex;
      gap: 20px;
      @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max) {
        gap: 10px;
      }
      button{
        border: none;
        height: 40px;
        width: 130px;
        font-size: 16px;
        letter-spacing: 1px;
        cursor: pointer;
        font-weight: 600;
        @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max) {
          width: 100px;
          height: 30px;
          font-size: 14px;
        }
      }
      .sign-in-button{
        background-color: black;
        color: white;
        border-radius: 20px;
      }
      .sign-up-button{
        background-color: #f1f1f1;
        color: black;
        border-radius: 20px;
      }
      .sign-out-button{
        background-color: white;
        color: black;
        border-radius: 20px;
        margin-top: 5px;
      }
      .profile{
        img{
          height: 45px;
          width: 45px;
          border-radius: 40px;
          @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max) {
            height: 35px;
            width: 35px;
            border-radius: 35px;
          }
        }
      }
    }
  }
</style>
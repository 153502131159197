<template>
  <div>
    <div class="hero-banner">
      <div class="left">
        <div class="text">
          <div class="title"> BIZ Extractor</div>
          <div class="desc">Get <span>LEADS</span> easy</div>
          <button 
            v-if="!token"
            class="sign-in"
            @click.prevent="clickSignin"
          >
            Sign in now
          </button>
          <button 
            v-else
            class="request-data"
            @click.prevent="requestDataModelClick"
          >
            Request data
          </button>
        </div>
      </div>
      <div class="right">
        <div class="player">
          <vue-playr>
            <div 
              data-plyr-provider="youtube" 
              data-plyr-embed-id="gwYIwyIebqg"
            ></div>
          </vue-playr>
        </div>
      </div>
    </div>
    <Package />
    <AboutUs />
    <ContactUs />
    <RequestDataModal :open="openRequestDataModal" @closed="requestModalClosed" />
  </div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../store/utils";
import { FETCH_GMAP } from "../store/action.names";
import { SET_OPEN_SIGN_IN } from "../store/mutation.names";
import { NS_USER } from "../store/namespace.names";
import { 
  GET_TOKEN,
  GET_PROFILE
} from "../store/getter.names";
import { API_HOST } from "../global";
import {
    ValidationProvider,
    ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import RequestDataModal from "../components/modals/RequestDataModal";
import Package from "../components/Package";
import AboutUs from "../components/AboutUs";
import ContactUs from "../components/ContactUs";

@Component({
  name: "Home",
  components: {
    ValidationProvider,
    ValidationObserver,
    RequestDataModal,
    Package,
    AboutUs,
    ContactUs
  }
})
export default class Home extends Vue {
  @Action(namespaced(NS_USER, FETCH_GMAP)) fetchGmap;

  @Mutation(namespaced(NS_USER, SET_OPEN_SIGN_IN)) setOpenSignin;

  @Getter(namespaced(NS_USER, GET_TOKEN)) token;
  @Getter(namespaced(NS_USER, GET_PROFILE)) getProfile;

  openRequestDataModal = false;
  HOST = API_HOST;
  formData = {
    "email": "",
    "keyword": ""
  }

  // handleSubmit(){
  //   this.fetchGmap(this.formData).then((data) => {
  //     let instance = Vue.$toast.open("An email will be sent when data fetching done!");
  //   }).catch((e) => {
  //     console.log(e)
  //     reject(e);
  //   });
  // }

  requestDataModelClick(){
    if(this.getProfile && !this.getProfile.is_verified){
      Vue.$toast.error("Please verified your email first!");
      return;
    }
    this.openRequestDataModal = true;
  }

  clickSignin(){
    this.setOpenSignin({data: true});
  }

  requestModalClosed(value){
    this.openRequestDataModal = false;
    if(value == "data_request"){
      Vue.$toast.open("An email will be sent when data fetching done!");
    }
  }
}
</script>

<style scoped lang="scss">
  .hero-banner{
    height: 100vh;
    width: 100%;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(39,33,89,0.8855917366946778) 25%, rgba(2,22,22,1) 100%, rgba(2,15,15,0.7231267507002801) 100%, rgba(15,27,27,1) 100%, rgba(52,242,242,1) 100%);
    display: flex;
    justify-content: space-between;
    @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max), (max-width: $tablet-breakpoint-max) {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 800px;
    }
    img{
      width: 100%;
    }
    .left{
      display: flex;
      align-items: center;
      @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max), (max-width: $tablet-breakpoint-max) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .text{
        color: white;
        // margin-top: 200px;
        margin-left: 200px;
        font-size: 50px;
        letter-spacing: 2px;
        @media (max-width: $small-breakpoint-max) {
          margin-left: 10px;
          font-size: 35px;
          margin-top: 70px;
        }
        @media (max-width: $mobile-breakpoint-max), (max-width: $tablet-breakpoint-max) {
          margin-left: 30px;
          font-size: 35px;
          margin-top: 70px;
        }
        .desc{
          margin-top: 50px;
          @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max), (max-width: $tablet-breakpoint-max) {
            margin-top: 30px;
          }
          span{
            background-color: yellow;
            color: black;
            padding: 10px;
            font-size: 40px;
            border-radius: 10px;
            @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max), (max-width: $tablet-breakpoint-max) {
              font-size: 25px;
            }
          }
        }
      }
      .sign-in{
        margin-top: 50px;
        border: none;
        background-color: #f1f1f1;
        font-size: 30px;
        padding: 10px;
        padding-left: 35px;
        padding-right: 35px;
        border-radius: 10px;
        letter-spacing: 2px;
        cursor: pointer;
        @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max), (max-width: $tablet-breakpoint-max) {
          font-size: 20px;
          padding-left: 15px;
          padding-right: 15px;
          font-size: 14px;
          margin-top: 30px;
        }
      }
      .request-data{
        margin-top: 50px;
        border: none;
        background-color: #f1f1f1;
        font-size: 30px;
        padding: 10px;
        padding-left: 35px;
        padding-right: 35px;
        border-radius: 10px;
        letter-spacing: 2px;
        cursor: pointer;
        @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max), (max-width: $tablet-breakpoint-max) {
          font-size: 20px;
          padding-left: 15px;
          padding-right: 15px;
          font-size: 14px;
          margin-top: 30px;
        }
      }
    }
    .right{
      display: flex;
      align-items: center;
      @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max), (max-width: $tablet-breakpoint-max) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 60px;
      }
      .player{
        width: 560px;
        height: 315px;
        margin-right: 200px;
        @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max), (max-width: $tablet-breakpoint-max) {
          width: 90%;
          margin: 0 auto;
        }
      }
    }
  }
  // .wrapper{
  //   display: flex;
  //   flex-direction: column;
  //   width: 500px !important;
  //   height: 500px;
  //   border: 1px solid #f1f1f1;
  //   box-shadow: 10px 10px 5px 10px #aaaaaa;
  // }
  // .header{
  //   display: flex;
  //   justify-content: center;
  //   padding: 10px;
  //   border-bottom: 1px solid #f1f1f1;
  //   h2{
  //     font-weight: 500;
  //     letter-spacing: 1px;
  //     font-size: 30px;
  //   }
  // }
  // .form{
  //   margin-top: 50px;
  // }

  // .input-wrapper{
  //   width: 80%;
  //   margin: 0 auto;
  //   margin-bottom: 20px;
  //   span{
  //     display: flex;
  //     flex-direction: column;
  //     label{
  //       font-weight: 300;
  //       font-size: 14px;
  //       margin-bottom: 10px;
  //     }
  //     input{
  //       height: 40px;
  //       font-size: 16px;
  //     }
  //     span{
  //       color: red;
  //       font-size: 14px;
  //       margin-top: 5px;
  //     }
  //   }
  //   button{
  //     height: 40px;
  //     width: 120px;
  //     border: none;
  //     font-size: 16px;
  //     font-weight: 500;
  //     color: white;
  //     background-color: black;
  //     cursor: pointer;
  //   }
  // }
</style>
<template>
  <div class="package">
    <div class="title">
      <span>Contact Us</span>
    </div>
    <div class="items">
      <div class="left">
        <div class="hero-title">
          Contact Info
        </div>
        <div class="visit">
          Tamarind Square, Cyberjaya, Malaysia. Khan Younis, Gaza, Palestine.
        </div>
        <div class="talk">
          <a href="mailto:info@techtsy.com">Email: info@techtsy.com</a><br><br>
          <a href="tel:+60176279891">Call: +60 17-627 9891</a>
        </div>
      </div>
      <div class="right" v-if="position">
        <iframe class="ifall" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d27259.704087899416!2d34.303999!3d31.346200000000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14fd9044e2ecd025%3A0xb4b53b91588bd26f!2sKhan%20Yunis!5e0!3m2!1sen!2sbd!4v1642321740945!5m2!1sen!2sbd" width="600" height="400" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
        <iframe class="ifm" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d27259.704087899416!2d34.303999!3d31.346200000000003!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14fd9044e2ecd025%3A0xb4b53b91588bd26f!2sKhan%20Yunis!5e0!3m2!1sen!2sbd!4v1642321740945!5m2!1sen!2sbd" width="300" height="400" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "ContactUs",
  components: {
  }
})
export default class ContactUs extends Vue {
  showInfo = false;
  position = {
    lat: 44.2899,
    lng: 11.8774
  };
  title = "Test title";
  description = "Test description";
}
</script>

<style scoped lang="scss">
  .package{
    min-height: 500px;
    width: 70%;
    margin: 0 auto;
    @media (max-width: $tablet-breakpoint-max) {
      width: 90%;
    }
    .title{
      text-align: center;
      margin-top: 50px;
      font-size: 40px;
      font-weight: 700;
      letter-spacing: 2px;
      span{
        padding-bottom: 10px;
        border-bottom: 10px solid yellow;
      }
    }
    .items{
      display: flex;
      flex-wrap: wrap;
      gap: 50px;
      justify-content: center;
      margin-top: 100px;
      margin-bottom: 0px;
      font-size: 30px;
      @media (max-width: $tablet-breakpoint-max) {
        gap: 40px;
      }
    }
    .left{
      height: 450px;
      width: 500px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: flex-start;
      justify-content: center;
      @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max), (max-width: $tablet-breakpoint-max) {
        height: auto;
      }
      .hero-title{
        font-weight: 700;
        font-size: 40px;
      }
      .visit{
        margin-top: 20px;
        font-size: 16px;
        color: black;
      }
      .talk{
        margin-top: 20px;
        font-size: 16px;
        color: black;
        a{
          color: black;
          text-decoration: underline;
          &:hover{
            color: blue;
            cursor: pointer;
          }
        }
      }
    }
    .right{
      height: 450px;
      width: 600px;
      .ifm{
        display: none;
      }
      @media (max-width: $small-breakpoint-max){
        width: auto;
        .ifall{
          display: none;
        }
        .ifm{
          display: block;
        }
      }
    }
  }
</style>
<template>
  <div>
      <sui-modal 
        v-model="showModal" 
        :closable="false" 
        :closeIcon="true" 
        size="small"
        @displayChanged="displayChanged"
      >
        <div class="modal-body">
          <div class="left-side">
            <div class="top-text">
              <h1>Request Data</h1>
              <p>Get access to your Profile & extract business data</p>
            </div>
            <div class="bottom-img">
              <!-- <img src="@/assets/images/horizontal-gmap.png"> -->
            </div>
          </div>
          <div class="right-side">
            <ValidationObserver v-slot="{ invalid }">
              <form @submit.prevent="handleSubmit">
                <div class="input-wrapper">
                  <ValidationProvider
                    rules="required"
                    v-slot="{ errors }"
                  >
                    <input 
                      type="text" 
                      name="keyword"
                      placeholder="Gmap Keyword" 
                      v-model="formData.keyword"
                    />
                    <span v-if="errors[0]">* {{ errors[0] }}</span>
                  </ValidationProvider>
                </div>

                <div class="input-wrapper">
                  <p class="error" v-html="error_msg"></p>
                </div>

                <div class="input-wrapper">
                  <button :disabled="invalid" type="submit" class="sign-up-btn">
                    Submit request
                  </button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </sui-modal>
  </div>

</template>

<script>
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { namespaced } from "../../store/utils";
import { NS_USER } from "../../store/namespace.names";
import { GET_PROFILE } from "../../store/getter.names";
import { 
  FETCH_GMAP, 
} from "../../store/action.names";

import {
    ValidationProvider,
    ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";

@Component({
  name: "RequestDataModal",
  components: {
    ValidationProvider,
    ValidationObserver,
  }
})
export default class RequestDataModal extends Vue {
  @Prop() open;
  @Action(namespaced(NS_USER, FETCH_GMAP)) fetchGmap;

  @Getter(namespaced(NS_USER, GET_PROFILE)) profile;

  showModal = false;
  formData = {
    keyword: "",
  }
  error_msg = "";

  handleSubmit(){
    if(this.profile.request_limit < 1){
      this.error_msg = `<p>Request limit exceed! Please buy package!</p>`;
    }else{
      this.fetchGmap(this.formData).then((data) => {
        this.closeRequestDataModal("data_request");
        this.formData = {
          keyword: ""
        }
        this.error_msg = "";
      }).catch((e) => {
        this.error_msg = "";
        var err_msg = "";
        for (const [key, value] of Object.entries(e.response.data)) {
          const err = `<p>${value}</p>`;
          err_msg = err_msg + err;
        }
        this.error_msg = err_msg;
      })
    }
  }

  @Watch("open")
  changeShowModal(val, oldVal){
    this.showModal = this.open;
  }

  displayChanged(value){
    if(value === "closed"){
      this.$emit("closed");
    }
  }

  closeRequestDataModal(data){
    this.$emit("closed", data);
  }

  created(){
    this.showModal = this.open;
  }
}
</script>

<style scoped lang="scss">
  .modal-body{
    display: grid;
    grid-template-columns: 1.2fr 2fr;
    @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max) {
      display: flex;
      flex-direction: column;
    }
    .left-side{
      background: rgb(2,0,36);
      background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(79,79,131,1) 0%, rgba(148,190,203,1) 39%, rgba(0,212,255,1) 100%);
      min-height: 500px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 20px;
      padding-top: 60px;
      @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max) {
        min-height: 100px;
      }
      .top-text{
        h1{
          color: black;
          font-weight: 700;
        }
        p{
          color: black;
          font-weight: 400;
          letter-spacing: 1px;
        }
      }
      .bottom-img{
        display: flex;
        justify-content: center;
        img{
          width: 300px;
        }
      }
    }
    .right-side{
      padding: 20px;
      padding-top: 60px;
      display: flex;
      align-items: center;
      span{
        width: 100%;
      }
      form{
        width: 100%;
        .input-wrapper{
          margin-bottom: 20px;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .acc-create-succ-msg{
            font-size: 24px;
            line-height: 40px;
            font-weight: 400;
            color: #012D3A;
          }
          span{
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            input{
              height: 40px;
              border: 1px solid black;
              padding: 10px;
              width: 80%;
            }
            span{
              font-weight: 300;
              text-align: left;
              width: 80%;
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              padding: 10px;
              padding-left: 0px;
              padding-bottom: 0px;
              font-size: 12px;
              color: red;
            }
          }
          .resend-text{
            margin-top: 10px;
            font-weight: 300;
          }
          p{
            width: 80%;
            line-height: 25px;
            font-weight: 400;
            a:hover{
              text-decoration: underline;
            }
          }
          .sign-up-btn{
            width: 80%;
            padding: 10px 0;
            border-radius: 20px;
            border: none;
            font-size: 17px;
            font-weight: 550;
            background: #012D3A;
            color: #fff;
            cursor: pointer;
          }
          .existing-user-btn{
            background-color: #f1f1f1;
            color: #012D3A;
            font-weight: 300;
          }
        }
      }
    }
  }
  .error{
    color: red;
  }
</style>
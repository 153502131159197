<template>
  <div class="personal-wrapper">
    <div class="image" v-if="getProfile">
			<img 
				v-if="getProfile.image" 
				:src="HOST + getProfile.image"
			>
			<img 
				v-else
				src="@/assets/images/empty-user.png" 
			/>
			<div class="change-image">
				<form enctype="multipart/form-data">
					<label for="change_image">Change Image</label>
					<input 
						type="file" 
						id="change_image" 
						accept="image/*" 
            @change="uploadImage($event)"
					/>
				</form>
			</div>
    </div>
		<div class="content">
			<div class="item">
				<div class="label">
					Full name: 
				</div>
				<div class="value" v-if="getProfile.full_name">
					{{ getProfile.full_name }}
				</div>
			</div>
			<div class="item">
				<div class="label">
					Username: 
				</div>
				<div class="value">
					{{ getProfile.username }}
				</div>
			</div>
			<div class="item">
				<div class="label">
					Email: 
				</div>
				<div class="value">
					{{ getProfile.email }}
				</div>
			</div>
			<div class="item">
				<div class="label">
					User Type: 
				</div>
				<div class="value" v-if="getProfile.type == 'general'">
					General
				</div>
				<div class="value" v-if="getProfile.type == 'premium'">
					Premium
				</div>
			</div>
			<div class="item">
				<div class="label">
					Active: 
				</div>
				<div class="value" v-if="getProfile.is_active == true">
					Active
				</div>
				<div class="value" v-else>
					Inactive
				</div>
			</div>
			<div class="item">
				<button 
					@click.prevent="showProfileEditModal = true"
				>
					Change Info
				</button>
				<button 
					@click.prevent="showChangePasswordModal = true"
				>
					Change Password
				</button>
			</div>
		</div>
    <ProfileEditModal 
			v-if="getProfile && showProfileEditModal"
			:data="getProfile"
			:open="showProfileEditModal" 
			@closed="profileEditModalClosed" 
		/>
		<ChangePasswordModal 
			v-if="getProfile && showChangePasswordModal"
			:open="showChangePasswordModal" 
			@closed="changePasswordModalClosed" 
		/>
  </div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../store/utils";
import { UPDATE_PROFILE } from "../store/action.names";
import { NS_USER } from "../store/namespace.names";
import { 
  GET_TOKEN,
  GET_PROFILE
} from "../store/getter.names";
import { API_HOST } from "../global";
import ProfileEditModal from "./modals/ProfileEditModal";
import ChangePasswordModal from "./modals/ChangePasswordModal";


@Component({
  name: "Personal",
  components: {
		ProfileEditModal,
		ChangePasswordModal
  }
})
export default class Personal extends Vue {
  @Getter(namespaced(NS_USER, GET_PROFILE)) getProfile;
	
	@Action(namespaced(NS_USER, UPDATE_PROFILE)) updateProfile;

	HOST = API_HOST;
	showProfileEditModal = false;
	showChangePasswordModal = false;

	uploadImage(event){
    var formData = new FormData();
    formData.append("image", event.target.files[0]);
    this.updateProfile(formData).then((data) => {
      console.log(data);
    })
  }

	profileEditModalClosed(){
    this.showProfileEditModal = false;
  }

	changePasswordModalClosed(value){
    this.showChangePasswordModal = false;
		if(value == "success"){
			Vue.$toast.open("Password updated successfully!");
		}
  }

}
</script>

<style scoped lang="scss">
  .personal-wrapper{
      min-height: 500px;
      padding: 20px;
			box-shadow: 0 6px 8px 6px rgba(0,0,0,0.2);
			.image{
				display: flex;
				justify-content: center;
				flex-direction: column;
				align-items: center;
				img{
					height: 150px;
					width: 150px;
					border-radius: 150px;
					margin-bottom: 30px;
				}
				.change-image{
					form{
						label{
							padding: 15px;
							border: 1px solid black;
							cursor: pointer;
							font-weight: 600;

						}
						input{
							display: none;
						}
					}
				}
			}
			.content{
				width: 400px;
				margin: 0 auto;
				margin-top: 50px;
				.item{
					display: flex;
					gap: 30px;
					padding: 10px;
					@media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max) {
						display: flex;
						flex-direction: column;
						gap: 15px;
					}
					.label{
						font-weight: 600;
						font-weight: 20px;
						width: 100px;
					}
					.value{
						font-weight: 400;
					}
					button{
						border: none;
						height: 40px;
						width: 150px;
						font-weight: 600;
						background-color: white;
						color: black;
						border: 1px solid black;
						cursor: pointer;
					}
				}
			}
  }
</style>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('sui-modal',{attrs:{"closable":false,"closeIcon":true,"size":"small"},on:{"displayChanged":_vm.displayChanged},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"left-side"},[_c('div',{staticClass:"top-text"},[_c('h1',[_vm._v("Change Password")]),_c('p',[_vm._v("Get access to your Profile & extract business data")])]),_c('div',{staticClass:"bottom-img"})]),_c('div',{staticClass:"right-side"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleChangePassword.apply(null, arguments)}}},[_c('div',{staticClass:"input-wrapper"},[_c('ValidationProvider',{attrs:{"rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.old_password),expression:"formData.old_password"}],attrs:{"type":"password","name":"old password","placeholder":"Old Password"},domProps:{"value":(_vm.formData.old_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "old_password", $event.target.value)}}}),(errors[0])?_c('span',[_vm._v("* "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"input-wrapper"},[_c('ValidationProvider',{attrs:{"vid":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.new_password),expression:"formData.new_password"}],attrs:{"type":"password","name":"new password","placeholder":"New Password"},domProps:{"value":(_vm.formData.new_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "new_password", $event.target.value)}}}),(errors[0])?_c('span',[_vm._v("* "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"input-wrapper"},[_c('ValidationProvider',{attrs:{"vid":"password2","rules":"required|min:8|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.new_password2),expression:"formData.new_password2"}],attrs:{"type":"password","name":"retype new password","placeholder":"Retype New Password"},domProps:{"value":(_vm.formData.new_password2)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "new_password2", $event.target.value)}}}),(errors[0])?_c('span',[_vm._v("* "+_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1),_c('div',{staticClass:"input-wrapper"},[_c('p',{staticClass:"error",domProps:{"innerHTML":_vm._s(_vm.error_msg)}})]),_c('div',{staticClass:"input-wrapper"},[_c('button',{staticClass:"sign-up-btn",attrs:{"disabled":invalid,"type":"submit"}},[_vm._v(" Change Password ")])])])]}}])})],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
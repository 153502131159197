<template>
  <div>
    <sui-table single-line v-if="results">
      <sui-table-header>
        <sui-table-row>
          <sui-table-header-cell>Transaction ID</sui-table-header-cell>
          <sui-table-header-cell>Package</sui-table-header-cell>
          <sui-table-header-cell>Price ($)</sui-table-header-cell>
          <sui-table-header-cell>Date</sui-table-header-cell>
        </sui-table-row>
      </sui-table-header>
      <sui-table-body>
        <sui-table-row
          v-for="(t, idx) in results"
          :key="'trans_' + idx"
        >
          <sui-table-cell>{{ t.transaction_id }}</sui-table-cell>
          <sui-table-cell>
            {{ t.subscription_detail.package_detail.name }}
          </sui-table-cell>
          <sui-table-cell>{{ t.price }}</sui-table-cell>
          <sui-table-cell>{{ moment(t.timestamp) }}</sui-table-cell>
        </sui-table-row>
      </sui-table-body>
    </sui-table>
    <div class="ui pagination menu" v-if="paginationSteps.length > 1">
      <a 
        class='item'
        @click.prevent="handleDecrement"
      >
        <sui-icon :disabled="page == 1" name="angle left" />
      </a>
      <a 
        :class='{"active": page == pag, "item": true}'
        v-for="(pag, idx) in paginationSteps"
        :key="'pag_' + idx"
        @click.prevent="page = pag"
      >
        {{ pag }}
      </a>
      <a 
        class='item'
        @click.prevent="handleIncreament"
      >
        <sui-icon :disabled="totalPageCount == page" name="angle right" />
      </a>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../store/utils";
import { FETCH_TRANSACTIONS } from "../store/action.names";
import { SET_OPEN_SIGN_IN } from "../store/mutation.names";
import { NS_USER } from "../store/namespace.names";
import { 
  GET_TOKEN,
  GET_PROFILE,
} from "../store/getter.names";
import { API_HOST } from "../global";
import { momentFormat } from "../store/utils";

@Component({
  name: "Transactions",
  components: {
  }
})
export default class Transactions extends Vue {
  @Action(namespaced(NS_USER, FETCH_TRANSACTIONS)) fetchTransactions;

  moment = momentFormat;
  page = 1;
  results = [];
  count = "";
  paginationSteps = [this.page];
  totalPageCount = "";

  @Watch("page")
  handlePageChange(val, oldVal){
    if(val != oldVal){
      this.fetchData();
      this.paginationSteps = [this.page];
    }
  }

  handleDecrement(){
    if(this.page > 1){
      this.page = this.page - 1;
    }else{
      this.page = 1;
    }
  }
  handleIncreament(){
    if(this.page < this.totalPageCount){
      this.page = this.page + 1;
    }else{
      this.page = this.totalPageCount;
    }
  }

  calculateNext(totalPage){
    if(totalPage > 1){
      var next = this.page;
      for(var i=0; i<totalPage; i++){
        if(i > 3){
          break;
        }
        if(this.page < totalPage){
          next = next + 1;
          if(next > totalPage){
            break;
          }
          this.paginationSteps.push(next);
        }
      }
    }
  }

  calculatePrev(){
    if(this.page > 1){
      var counter = 0;
      for(var i=this.page - 1; i>0; i--){
        if(counter > 3){
          break;
        }
        this.paginationSteps.unshift(i);
        counter += 1;
      }
    }
  }

  calculatePage(){
    const totalPage = Math.ceil(this.count/20);
    this.totalPageCount = totalPage;
    if(totalPage > 1){
      this.calculateNext(totalPage);
      this.calculatePrev();
    }
  }

  fetchData(){
    this.fetchTransactions({
      "page": this.page
    }).then((data) => {
      this.results = data.results;
      this.count = data.count;
      this.calculatePage();
    })
  }

  mounted(){
    this.fetchData();
  }
}
</script>

<style scoped lang="scss">
  
</style>
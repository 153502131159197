import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vueDebounce from 'vue-debounce';
import axios from "axios";
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import SuiVue from "semantic-ui-vue";
import "semantic-ui-css/semantic.min.css";
import VuePlayr from 'vue-playr';
import 'vue-plyr/dist/vue-plyr.css';
import VueGoogleMap from 'vuejs-google-maps';
import 'vuejs-google-maps/dist/vuejs-google-maps.css';


import { namespaced } from "./store/utils";
import { NS_USER } from "./store/namespace.names";
import { GET_TOKEN_FROM_LOCAL_STORE } from "./store/action.names";
import { GET_TOKEN } from "./store/getter.names";


import DefaultLayout from "./layouts/DefaultLayout";
import FullPageLayout from "./layouts/FullPageLayout";

Vue.component("default-layout", DefaultLayout);
Vue.component("fullpage-layout", FullPageLayout);

Vue.use(vueDebounce);
Vue.use(VueToast);
Vue.use(SuiVue);
Vue.use(VuePlayr);
Vue.use(VueGoogleMap, {
    load: {
        apiKey: "AIzaSyB-h9Hr3kwi84ViYgpWzdvX7VNnTwELngw",
        libraries: [/* rest of libraries */]
    }
});

window.axios = axios;

Vue.config.productionTip = false

store.dispatch(namespaced(NS_USER, GET_TOKEN_FROM_LOCAL_STORE));
router.beforeEach((to, from, next) => {
    const loggedIn = store.getters[namespaced(NS_USER, GET_TOKEN)] != null;
    if (to.meta ?.loginRequired) {
        if (loggedIn) {
            next();
        } else {
            next("/");
        }
    } else {
        next();
    }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
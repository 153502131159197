<template>
  <div class="footer">
    <div>
      &copy; {{ new Date().getFullYear() }} Techtsy, All rights reserved.
    </div>
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "Footer",
  components: {
  }
})
export default class Footer extends Vue {

}
</script>

<style scoped lang="scss">
  .footer{
    height: 60px;
    background: rgb(2,0,36);
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(39,33,89,0.8855917366946778) 25%, rgba(2,22,22,1) 100%, rgba(2,15,15,0.7231267507002801) 100%, rgba(15,27,27,1) 100%, rgba(52,242,242,1) 100%);
    border-top: 1px solid #f1f1f1;
    display: flex;
    align-items: center;
    color: #f1f1f1;
    letter-spacing: 1.5px;
    font-weight: 500;
    font-size: 14px;
    div{
      width: 80%;
      margin: 0 auto;
      @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max) {
        width: 80%;
      }
    }
  }
</style>
<template>
  <div class="package">
    <div class="title">
      <span>Packages</span>
    </div>
    <div class="items" v-if="getPackages">
      <div 
        v-for="(getPackage, idx) in getPackages"
        :key="'package_' + idx"
        class="item"
      >
        <div class="head">
          {{ getPackage.name }}
        </div>
        <div class="body">
          <div class="row">
            <div class="label">
              Request limit
            </div>
            <div class="value">
              {{ getPackage.request_limit }}
            </div>
          </div>
          <div class="row">
            <div class="label">
              Data limit
            </div>
            <div class="value">
              {{ getPackage.data_limit_per_request }} / request
            </div>
          </div>
          <div class="row">
            <div class="label">
              Duration
            </div>
            <div class="value">
              {{ getPackage.duration }} days
            </div>
          </div>
          <div class="row price">
            <div class="label">
              Price
            </div>
            <div class="value">
              ${{ getPackage.price }}
            </div>
          </div>
          <div class="row sign-button">
            <button @click="handleSelection(getPackage)">
              Purchase
            </button>
          </div>
        </div>
      </div>
    </div>
    <PaymentModal 
      v-if="paymentModalOpen && selectedPackage"
      :data="selectedPackage"
      :open="paymentModalOpen" 
      @closed="paymentModalClosed" 
    />
  </div>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../store/utils";
import { NS_USER } from "../store/namespace.names";
import { 
  GET_PACKAGES,
  GET_PROFILE,
  GET_TOKEN
} from "../store/getter.names";
import PaymentModal from "./modals/PaymentModal";

@Component({
  name: "Package",
  components: {
    PaymentModal,
  }
})
export default class Package extends Vue {
  @Getter(namespaced(NS_USER, GET_PACKAGES)) getPackages;
  @Getter(namespaced(NS_USER, GET_PROFILE)) getProfile;
  @Getter(namespaced(NS_USER, GET_TOKEN)) getToken;

  paymentModalOpen = false;
  selectedPackage = null;

  handleSelection(getpackage){
    if(!this.getProfile || !this.getToken){
      Vue.$toast.error("Please sign in to purchase!");
      return;
    }
    this.selectedPackage = getpackage;
    this.paymentModalOpen = true;
  }

  paymentModalClosed(value){
    this.paymentModalOpen = false;
    if(value == "success"){
      Vue.$toast.open("Your payment received successfully!");
    }
  }
}
</script>

<style scoped lang="scss">
  .package{
    min-height: 500px;
    width: 70%;
    margin: 0 auto;
    @media (max-width: $tablet-breakpoint-max) {
      width: 80%;
    }
    .title{
      text-align: center;
      margin-top: 50px;
      font-size: 40px;
      font-weight: 700;
      letter-spacing: 2px;
      span{
        padding-bottom: 10px;
        border-bottom: 10px solid yellow;
      }
    }
    .items{
      display: flex;
      flex-wrap: wrap;
      gap: 50px;
      justify-content: center;
      margin-top: 100px;
      margin-bottom: 0px;
      @media (max-width: $tablet-breakpoint-max) {
        gap: 40px;
      }
      .item{
        // height: 400px;
        width: 300px;
        box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2);
        margin-bottom: 100px;
        border-radius: 10px;
        @media (max-width: $tablet-breakpoint-max) {
          width: 300px;
        }
        &:last-child{
          margin-bottom: 100px;
        }
        @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max) {
          margin-bottom: 30px;
        }
        .head{
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 22px;
          font-weight: 500;
          background-color: black;
          color: white;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        .body{
          .row{
            height: 60px;
            display: flex;
            gap: 20px;
            padding-left: 20px;
            padding-right: 20px;
            align-items: center;
            justify-content: space-between;
            font-size: 18px;
            font-weight: 500;
            border-bottom: 1px solid black;
            .value{
              font-size: 18px;
            }
          }
          .sign-button{
            border-bottom: none !important;
            display: flex;
            justify-content: center;
            height: 80px;
            button{
              border: none;
              height: 40px;
              padding: 10px;
              padding-left: 20px;
              padding-right: 20px;
              font-size: 14px;
              background: rgb(2,0,36);
              background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(39,33,89,0.8855917366946778) 20%, rgba(2,22,22,1) 100%, rgba(2,15,15,0.7231267507002801) 100%, rgba(15,27,27,1) 100%, rgba(52,242,242,1) 100%);
              color: white;
              border-radius: 6px;
              letter-spacing: 1px;
              font-weight: 700;
              cursor: pointer;
            }
          }
          .price{
            background-color: #f1f1f1;
          }
        }
      }
    }
  }
</style>
<template>
  <div class="package-wrapper">
    <div class="package" v-if="getProfile">
      <div class="name">
        <p v-if="getProfile.subscription_detail">
           {{ getProfile.subscription_detail.package_detail.name }}
        </p>
        <p v-else>
           TRIAL
        </p>
      </div>
      <div class="content">
        <div class="timeline" v-if="getProfile.subscription_detail">
          {{ moment(getProfile.subscription_detail.start_date) }} -
          {{ moment(getProfile.subscription_detail.end_date) }}
        </div>
        <div 
          v-if="getProfile"
          class="request-limit"
        >
          <div
            v-if="getProfile && getProfile.request_limit > 0"
          >
            {{ getProfile.request_limit }} requests left
          </div>
          <div
            v-else
          >
            Limit exceed
          </div>
        </div>
        <div 
          v-if="getProfile.subscription_detail"
          class="price"
        >
          Price - ${{ getProfile.subscription_detail.package_detail.price }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { namespaced } from "../store/utils";
import { FETCH_GMAP, PROFILE } from "../store/action.names";
import { SET_OPEN_SIGN_IN } from "../store/mutation.names";
import { NS_USER } from "../store/namespace.names";
import { 
  GET_TOKEN,
  GET_PROFILE
} from "../store/getter.names";
import { API_HOST } from "../global";
import { momentFormat } from "../store/utils";

@Component({
  name: "UserPackage",
  components: {
  }
})
export default class UserPackage extends Vue {
  @Getter(namespaced(NS_USER, GET_PROFILE)) getProfile;

  @Action(namespaced(NS_USER, PROFILE)) profile;

  moment = momentFormat;

  mounted(){
    this.profile();
  }

}
</script>

<style scoped lang="scss">
  .package-wrapper{
    .package{
      height: 300px;
      width: 450px;
      margin: 0 auto;
      background: rgb(2,0,36);
      background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(39,33,89,0.8855917366946778) 25%, rgba(2,22,22,1) 100%, rgba(2,15,15,0.7231267507002801) 100%, rgba(15,27,27,1) 100%, rgba(52,242,242,1) 100%);
      border-radius: 20px;
      color: white;
      padding: 20px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      justify-content: center;
      align-items: center;
      box-shadow: 0 6px 8px 6px rgba(0,0,0,0.2);
      @media (max-width: $small-breakpoint-max), (max-width: $mobile-breakpoint-max) {
        width: auto;
      }
      .name{
        p{
          font-size: 30px;
          font-weight: 700;
        }
      }
      .content{
        display: flex;
        flex-direction: column;
        gap: 10px;
        .timeline{
          font-weight: 400;
          font-size: 16px;
          letter-spacing: 1px;
        }
        .request-limit, .price{
          display: flex;
          justify-content: center;
          letter-spacing: 2px;
          font-size: 16px;
        }
      }
    }
  }
</style>